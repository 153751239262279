import React, { useState, useEffect, useCallback } from 'react';
import { PanelProps } from '@grafana/data';
import { Alert, Button, Input, Field, FieldSet } from '@grafana/ui';
import { connectMQTT, publishAndSubscribe, unsubscribeFromMQTT, disconnectMQTT, MQTTConfig} from './utils/mqttService';

interface MQTTOptions {
  brokerUrl: string;
  username?: string;
  password?: string;
}

interface Props extends PanelProps<MQTTOptions> {}

export const MQTTPanel: React.FC<Props> = ({ options, data, width, height }) => {
  const [deviceId, setDeviceId] = useState('');
  const [topic, setTopic] = useState('');
  const [message, setMessage] = useState('');
  const [connectionStatus, setConnectionStatus] = useState<'Connected' | 'error' | null>(null);
  const [ackStatus, setAckStatus] = useState<string | null>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showAckStatus, setShowAckStatus] = useState(false);
  const [ackTimestamp, setAckTimestamp] = useState(null);


  useEffect(() => {
    return () => {
      disconnectMQTT();
    };
  }, []);

  useEffect(() => {
    connectMQTT(options, (status) => setConnectionStatus(status));
  }, [options]);


  const updateAckStatus = (status) => {
    setAckStatus(status);
    setAckTimestamp(new Date().toLocaleString());
    setShowAckStatus(true);
  };

  const handleMQTTMessage = (receivedTopic: string, receivedMessage: string) => {
    updateAckStatus(`${receivedMessage}`);
  };


  const handleSubmit = () => {
    if (!topic || !message) {
      updateAckStatus('Please provide both topic and message');
      return;
    }

    if (connectionStatus !== 'Connected') {
      updateAckStatus('Please connect to MQTT broker first');
      return;
    }

    // Publish the message
    publishAndSubscribe(topic, message, handleMQTTMessage);
    updateAckStatus('Sending command...');


    // Set a timeout to clear the ack status and unsubscribe if no response
    setTimeout(() => {
      setAckStatus((currentStatus) => {
        if (currentStatus === 'Sending command...') {
          unsubscribeFromMQTT(topic);
          updateAckStatus('No acknowledgment received');
        }
        return currentStatus;
      });
    }, 10000);

  };

  const ackStatusText = ackStatus || '';


  return (
    <div style={{ width, height, overflow: 'auto', padding: '10px' }}>
      <FieldSet label="MQTT Command">
        <Input value={topic} onChange={(e) => setTopic(e.currentTarget.value)} placeholder="MQTT Topic" />
        <Input value={message} onChange={(e) => setMessage(e.currentTarget.value)} placeholder="Message" />
        <Button onClick={handleSubmit}>Send</Button>

      </FieldSet>

      {connectionStatus && <p>MQTT: {connectionStatus}</p>}

      {showAckStatus && <p>Status: {ackStatusText} @ {ackTimestamp}</p>}

    </div>
  );
};

