import * as mqtt from 'mqtt/dist/mqtt.min';

let client: mqtt.MqttClient;

interface MQTTConfig {
  brokerUrl: string;
  username?: string;
  password?: string;
}

export const connectMQTT = (config: MQTTConfig, statusCallback: (status: 'connected' | 'error') => void) => {
  const { brokerUrl, username, password } = config;

  const options: mqtt.IClientOptions = {
    username,
    password,
  };

  client = mqtt.connect(brokerUrl, options);

  client.on('connect', () => {
    console.log('Connected to MQTT broker');
    statusCallback('Connected');
  });

  client.on('error', (error) => {
    console.error('MQTT connection error:', error);
    statusCallback('Error');
  });
};

export const publishToMQTT = (config: MQTTConfig, topic: string, message: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!client || !client.connected) {
      reject(new Error('MQTT client not connected'));
      return;
    }

    client.publish(topic, message, (error) => {
      if (error) {
        console.error('Publish error:', error);
        reject(error);
      } else {
        console.log('Message published successfully');
        resolve();
      }
    });
  });
};

export const publishAndSubscribe = (topic: string, message: string, callback: (topic: string, message: string) => void) => {
  if (client && client.connected) {
    const statusTopic = `status/${topic}`;
    // Subscribe first
    client.subscribe(statusTopic, (err) => {
      if (err) {
        console.error('Subscription error:', err);
        return;
      }

      // Set up message handler
      client.on('message', (receivedTopic, payload) => {
        if (receivedTopic === statusTopic) {
          callback(receivedTopic, payload.toString());
        }
      });

      // Then publish
      client.publish(topic, message, (err) => {
        if (err) {
          console.error('Publish error:', err);
        } else {
          console.log('Message published');
        }
      });
    });
  } else {
    console.error('MQTT client not connected');
  }
};

export const unsubscribeFromMQTT = (topic: string) => {
  if (client && client.connected) {
    client.unsubscribe(`status/${topic}`);
  } else {
    console.error('MQTT client not connected');
  }
};


export const subscribeToMQTT = (topic: string, callback: (topic: string, message: string) => void) => {
  if (client && client.connected) {
    client.subscribe(topic);
    client.on('message', (receivedTopic, message) => {
      if (receivedTopic === topic) {
        callback(receivedTopic, message.toString());
      }
    });
  } else {
    console.error('MQTT client not connected');
  }
};


export const disconnectMQTT = () => {
  if (client) {
    client.end();
    client = null;
  }
};
